<template>
  <v-card class="mt-5" width="100%">
    <v-card-title> Bancos </v-card-title>
    <v-card-subtitle class="text-left">
      Lista de bancos cadastrados
    </v-card-subtitle>
    <v-divider
      style="border: solid 2px"
      class="deep-orange darken-2"
    ></v-divider>
    <div style="position: relative">
      <v-fab-transition>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mr-3"
              @click="toogleRegisterbankDilog"
              v-bind="attrs"
              v-on="on"
              color="deep-orange darken-2"
              fab
              dark
              small
              absolute
              top
              right
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Cadastrar novo banco</span>
        </v-tooltip>
      </v-fab-transition>
    </div>
    <v-card-text class="">
      <v-data-table
        :headers="headers"
        :items="$store.state.configurdBanksList"
        :items-per-page="5"
        class="elevation-0 mt-2"
        no-data-text="Nenhum banco configurado"
      >
        <template v-slot:item.bank.bank_name="{ item }">
          <div>{{ item.bank.bank_name }}</div>
          <div>
            <small
              >Convênio:
              <b>{{
                item.title_config ? item.title_config.numeroConvenio : "-"
              }}</b></small
            >
          </div>
        </template>
        <template v-slot:item.title_config.numeroCarteira="{ item }">
          {{
            item.title_config && item.title_config.numeroCarteira
              ? item.title_config.numeroCarteira
              : "-"
          }}
        </template>
        <template v-slot:item.title_config.orgaoNegativador="{ item }">
          {{ item.title_config ? item.title_config.orgaoNegativador : "-" }}
        </template>

        <!-- <template v-slot:item.title_config.algoritmoNumeracao="{ item }">
                    {{ item.title_config ? `Tipo(${item.title_config.algoritmoNumeracao})` : "-" }}
                </template> -->
        <template v-slot:item.actions="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primaryNew" dark icon v-bind="attrs" v-on="on">
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <v-list dense rounded class="">
              <v-subheader class="text-subtitle-2">
                <b class="primaryNew--text">
                  {{ item.bank.bank_name }}
                </b></v-subheader
              >
              <v-divider class="mb-2"></v-divider>

              <v-list-item @click="editConfigBank(item)">
                <v-list-item-icon class="mr-4">
                  <v-icon>drive_file_rename_outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Editar banco</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="confirmDelConfig(item)">
                <v-list-item-icon class="mr-4">
                  <v-icon>delete_forever</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Excluir banco</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card-text>
    <RegisterBank
      :adm="false"
      :verifySteps="verifySteps"
      :getConfiguredBanks="getConfiguredBanks"
      :editConfigData="editConfigData"
      :registerbankDilog="registerbankDilog"
      :toogleRegisterbankDilog="toogleRegisterbankDilog"
    />
  </v-card>
</template>

<script>
// import RegisterBank from '../../../../components/clients/billets/banks/RegisterBank.vue'
import RegisterBank from "../../../../components/clients/billets/banks/DynamicRegisterBank.vue";
export default {
  methods: {
    toogleRegisterbankDilog() {
      this.registerbankDilog = !this.registerbankDilog;
      if (!this.registerbankDilog) {
        this.editConfigData = null;
      }
    },
    editConfigBank(data) {
      console.log("numeration cardBank");
      let obj = {
        id: data.id,
        bank: {
          number_bank: data.bank.number_bank,
          id: data.bank.id,
          bank_name: data.bank.bank_name,
        },
        numeroConvenio:
          data.title_config && data.title_config.numeroConvenio
            ? data.title_config.numeroConvenio
            : "",
        numeroCarteira:
          data.title_config && data.title_config.numeroCarteira
            ? data.title_config.numeroCarteira
            : "",
        orgaoNegativador:
          data.title_config && data.title_config.orgaoNegativador
            ? data.title_config.orgaoNegativador
            : "",
        numeroVariacaoCarteira:
          data.title_config && data.title_config.numeroVariacaoCarteira
            ? data.title_config.numeroVariacaoCarteira
            : "",
        agencia:
          data.title_config && data.title_config.agencia
            ? data.title_config.agencia
            : "",

        expiration_time_pix:
          data.title_config && data.title_config.expiration_time_pix
            ? data.title_config.expiration_time_pix
            : "",

        contaCorrente:
          data.title_config && data.title_config.contaCorrente
            ? data.title_config.contaCorrente
            : "",
        credentials: data.credentials,
        numeration: data.numeration ? data.numeration : {},
        algoritmoNumeracao:
          data.title_config && data.title_config.algoritmoNumeracao
            ? data.title_config.algoritmoNumeracao
            : "",
        numeracaoInicial:
          data.title_config && data.title_config.numeracaoInicial
            ? data.title_config.numeracaoInicial
            : "",
        tarifaLiquidado:
          data.title_config && data.title_config.tarifaLiquidado
            ? data.title_config.tarifaLiquidado
            : "",
        tarifaRegistro:
          data.title_config && data.title_config.tarifaRegistro
            ? data.title_config.tarifaRegistro
            : "",
        tarifaBaixa:
          data.title_config && data.title_config.tarifaBaixa
            ? data.title_config.tarifaBaixa
            : "",
        tarifaPix: data.title_config.tarifaPix,
        callback_url: data.callback_url,
      };
      for (var prop in data.credentials) {
        obj[prop] = data.credentials[prop];
        // .log(prop + " = " + data.credentials[prop]);
      }
      for (var prop in data.numeration) {
        obj[prop] = data.numeration[prop];
        // .log(prop + " = " + data.credentials[prop]);
      }

      this.editConfigData = { ...obj };
      this.toogleRegisterbankDilog();
    },
    confirmDelConfig(data) {
      let obj = {
        message: `Você deseja excluir a configuração do banco <b>${data.bank.bank_name}</b>?`,
        title: "Excluir",
        callback: (_) => {
          this.delConfig(data);
        },
        type: "warning",
        btConfirm: { name: "Sim", show: true },
        btCancel: { name: "Não", show: true },
      };
      this.$store.commit("confirmMessage/showConfirmMessage", obj);
    },
    delConfig(data) {
      // "partner_id":68,
      //   "contract_id":129,
      //   "service_id" : 3,
      //   "id":5
      let obj = {
        url: "/api/v1/title/user/bank/deleteUserBank",
        query: {
          id: data.id,
          service_id: 3,
          partner_id: localStorage.getItem("partner_id"),
        },
        method: "post",
      };
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        // let index = this.payment.findIndex((payment) => payment.id === data.id);
        // this.payment.splice(index, 1);
        let opts = {
          message: `Configuração removida com sucesso.`,
          type: "success",
        };
        this.$store.commit("showAlert", opts);
        this.getConfiguredBanks();
      });
    },
    async getConfiguredBanks(loader) {
      var obj = {
        noLoader: loader,
        url: "/api/v1/title/user/bank/getUserBank",
        query: null,
        method: "get",
      };
      await this.$store.dispatch("request/doRequest", obj).then((response) => {
        this.banksProfileRegisterCbo = [
          ...response.data.map((obj) => {
            return {
              number_bank: obj.bank.number_bank,
              pg_bank_favoured_id: obj.id,
              bank_name: obj.bank.bank_name,
            };
          }),
        ];
        this.$store.commit("updateConfigurdBanksList", response.data);
        // this.banks = [...response.data];
      });
    },
  },
  data() {
    return {
      editConfigData: null,
      registerbankDilog: false,
      verifySteps: null,
      banks: [],
      headers: [
        {
          text: "Banco",
          value: "bank.bank_name",
          align: "left",
          sortable: false,
        },
        {
            text: "Bank favoured id",
            value: "id",
            align: "center",
            sortable: false,
        },
        {
          text: "Carteira",
          value: "title_config.numeroCarteira",
          align: "center",
          sortable: false,
        },
        // {
        //     text: "Numeração",
        //     value: "title_config.algoritmoNumeracao",
        //     align: "center",
        //     sortable: false,
        // },
        {
          text: "Ações",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.getConfiguredBanks(false);
  },
  components: {
    RegisterBank,
  },
};
</script>

<style lang="scss" scoped></style>